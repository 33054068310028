<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="สถานะคิวจอง">
            <b-form-radio-group
              @change="load_data"
              v-model="form.order_status"
              :options="lock_options"
              name="radios-stacked3"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <filter-table :filter_data="filter_data"></filter-table>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BCard,
  BCardText,
  BRow,
  BCol,
  BTab,
  BTabs,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import FilterTable from "../component/FilterTable.vue";
import moment from "moment";
import store from "@/store/index";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTab,
    BTabs,
    BButton,
    VueGoodTable,
    FilterTable,
    BFormRadioGroup,
    BFormGroup,
  },
  data() {
    return {
      text: this.$route.params.text,
      filter_data: [],
      form: {
        side: "status",
        order_status: ["pending-confirm"],
        selected_page: 1,
        per_page: 100,
      },
      lock_options: [
        { text: "ล็อคคิว", value: "pending-confirm" },
        { text: "ไม่ได้ล็อคคิว", value: "pending-confirm-no-lock" },
      ],
    };
  },
  created() {
    this.load_data();

    console.log(this.$route);
  },
  methods: {
    load_data() {
      //   this.form.selected_page = 1;
      //   this.form.per_page = 100;
      this.$http({
        method: "POST",
        url: `/order/list/filter`,
        data: this.form,
      }).then((x) => {
        this.filter_data = x.data.data;
      });
    },
  },
};
</script>
